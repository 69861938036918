.css-1c2l6bs-MuiTimelineItem-root::before {
    content: "";
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 0px !important;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 8px !important;
    text-align: left;
}
.css-3sdw6b::before {
    content: "";
    flex: 1 1 0%;
    padding: 6px 0px !important;
}